import { Container } from "react-bootstrap";
import RESUME_IMG from "../../assets/images/resume.png";
import "./loading.scss";
import HeaderComponent from "../header/header";
import FooterComponent from "../footer/footer";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { UplaodResumeModel } from "../../model/upload-resume.model";
import ApiService from "../../services/api.service";
import { getUTMParams, handleNavigation } from "../../services/handler-utm.service";
import QuestionsModel from "../../model/questions.model";
import { ResultModel } from "../../model/result.model";
const LoadingComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [textMessage, setMessage] = useState("");
    const [uploadResumeObject, setUploadResumeObject] = useState<UplaodResumeModel>();
    const { utmSource, utmMedium } = getUTMParams(location);

    const [questionsModel, setQuestionsModel] = useState<QuestionsModel>();
    useEffect(() => {
        try {
            if (location.state.input) {
             
                if (location.state.input.resume) {
                    const resumeModel: UplaodResumeModel = location.state.input;
                    setUploadResumeObject(resumeModel);
                    setMessage("Uploading Resume . . .")
                }else if(location.state.input.questions){
                    const questionModel: QuestionsModel = location.state.input;
                    setQuestionsModel(questionModel);
                    setMessage("Checking . . .")
                }

            }
        } catch {
            handleNavigation(navigate, '/', utmSource, utmMedium);
        }

    }, [])

    useEffect(() => {
        if (uploadResumeObject) {
            callApiUpload();
        }

    }, [uploadResumeObject]);

    useEffect(() => {
        if(questionsModel){
            callApiResult();
        }
    },[questionsModel]);

    const callApiResult = () => {
        if(questionsModel){
            ApiService().postGetResult(questionsModel).then((res : ResultModel) => {
                console.log(res);
                if(res){
                    if(res.dating_description){
                        handleNavigation(navigate, '/result', utmSource, utmMedium, null, res);
                    }else{
                        callApiResult();
                    }
                }else{
                    callApiResult();
                }
                
               
            })
        }
    }

    const callApiUpload = () => {
        if (uploadResumeObject) {
            ApiService().uploadResume(uploadResumeObject.resume, uploadResumeObject.filename).then((res: UplaodResumeModel) => {

                console.log(res);
                const resumeKey: UplaodResumeModel = {
                    resume_key: res.resume_key
                }
                ApiService().postGetQuestion(resumeKey).then((res2: QuestionsModel) => {
                    console.log(res2);
                    if(res2){
                       
                        handleNavigation(navigate, '/questions', utmSource, utmMedium, null, res2);
                    }
                   
                })
            })
        }

    }
    return (

        <>
            <div className="loading-comp">
                <Container className="loading-comp-control">
                    <img src={RESUME_IMG} className="loading-comp-resume"></img>
                    <span className="loading-comp-resume-text">{textMessage}</span>

                </Container>
            </div>
        </>
    )
}

export default LoadingComponent;
import { Button, Form, Modal } from "react-bootstrap";
import "./email-submit.modal.scss";

import Lottie from "lottie-react";
import { useState } from "react";
import ApiService from "../services/api.service";



const EmailSubmitModal = ({ open, setOpen, log_uuid }: any) => {
    const [emailValue, setEmailValue] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const handleClose = () => {
        setOpen(false);
    };

    const validateEmail = (value: string) => {
        // Regular expression for email validation
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(value);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setEmailValue(newValue);
        setIsValidEmail(validateEmail(newValue));
    };

    const submit = () => {

        if(emailValue){
            const emailObejct = {
                email: emailValue
            }
            ApiService().postSubmitEmail(emailObejct,log_uuid).then((res) => {

                handleClose();
            })
        }
    }

    return (

        <>
            <Modal show={open} onHide={handleClose} >
                <Modal.Header className="email-submit-text-header" closeButton>
                Unlock Your Perfect Match
                </Modal.Header>
                <Modal.Body className="">

                    <div className="email-submit-modal">
                        <Form >
                            <Form.Group controlId="email">
                                <Form.Label className="email-submit-text-title">Get ready to embark on a journey towards meaningful connections. Enter your email address below to uncover your ideal match through Resume Dating. 
                                Let's find someone who resonates with your personality and aspirations. Your path to authentic romance starts here.</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter your email"
                                    value={emailValue}
                                    onChange={handleEmailChange}
                                    isInvalid={!isValidEmail}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid email address.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Button className="email-submit-button" onClick={submit}>Submit email</Button>
                        </Form>
                    </div>


                </Modal.Body>


            </Modal>
        </>
    )
}

export default EmailSubmitModal;
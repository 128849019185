import { Button, Container } from "react-bootstrap";
import HeaderComponent from "../header/header";
import "../questions/questions.scss";
import { useEffect, useState, useContext } from "react";
import RESUME_IMG from "../../assets/images/resume.png";
import RESUME_BUILDER from "../../assets/images/resume-builder.png";
import UtilityService from "../../utility/utility.service";
import config from "../../config";
import { useFilePicker } from 'use-file-picker';
import { UplaodResumeModel } from "../../model/upload-resume.model";
import { useLocation, useNavigate } from "react-router-dom";
import { getUTMParams, handleNavigation } from "../../services/handler-utm.service";
const UploadResume = () => {
    const navigate = useNavigate();
    const [fileUpload, setFileUpload] = useState<any>();
    const location = useLocation();
    const { utmSource, utmMedium} = getUTMParams(location);
    const [openFileSelector, { filesContent, loading, errors }] = useFilePicker({
        multiple: false,
        readAs: 'DataURL',
        accept: ['.json', '.pdf', 'image/*'],
        limitFilesConfig: { min: 0, max: 1 },
        maxFileSize: 20,
    });
    useEffect(() => {
        if (filesContent.length > 0) {
            setFileUpload(filesContent[0])
            const files = filesContent[0];
            const file = DataURIToBlob(files["content"]);
            const uploadObject : UplaodResumeModel = {
                filename: files.name,
                resume: file
            }
            handleNavigation(navigate, '/loading', utmSource, utmMedium,  null, uploadObject);
        }
    }, [filesContent])
    const DataURIToBlob = (dataURI: string) => {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)
        return new Blob([ia], { type: mimeString })
    }
    const clickResumeBuilder = () => {


        UtilityService().clickSendEvent("Click Resume Builder", "Click create resume");
        window.open("https://resume-builder.jobhack.co?utm_source=RESUME_DATING", "_blank");
    }
    return (

        <>
            <div className="questions">
                <HeaderComponent></HeaderComponent>
                <Container className="questions-control">
                    <div className="questions-text-control">
                        <h2 className="questions-text-title text-center">Begin with the enchanting step of uploading your resume."</h2>
                        <div className="question-upload-resume-control">
                            <img src={RESUME_IMG} className="question-upload-resume-img"></img>
                            <Button className="question-upload-resume-button" onClick={openFileSelector}>Choose Resume</Button>
                            <div className="question-upload-resume-create-control">
                                <span className="question-upload-resume-create-text">create your resume at <img src={RESUME_BUILDER} onClick={clickResumeBuilder} className="question-upload-resume-create-img"></img></span>
                            </div>
                        </div>


                    </div>




                </Container >

            </div >
        </>
    )
}

export default UploadResume;
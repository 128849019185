

const apiPath = {
    resume: "resume/",
    generator: "generate/",
    path: {
        result: "result",
        questions: "question",
        upload: "upload",
        log: "log/",
        user: "/user"
    }
}

export default {
    // Add common config values here
    ...apiPath
};



export function buildURLWithUTMParams(url, utmSource, utmMedium, redirect?) {
    const searchParams = new URLSearchParams();
    if (utmSource) {
        searchParams.set('utm_source', utmSource);
    }
    if (utmMedium) {
        searchParams.set('utm_medium', utmMedium);
    }

    if(redirect){
        searchParams.set('redirect', redirect);
    }

    const queryString = searchParams.toString();
    return queryString ? `${url}?${queryString}` : url;
}
export const handleNavigation = (navigate, page, utmSource, utmMedium,  redirect?, object?) => {

    if(redirect){
        console.log(redirect);
        const newURL = buildURLWithUTMParams(page, utmSource, utmMedium, redirect);
        navigate(newURL);
    }else if(object){
        const newURL = buildURLWithUTMParams(page, utmSource, utmMedium );
        navigate(newURL, { state: { input: object } });
    }
    else{
        const newURL = buildURLWithUTMParams(page, utmSource, utmMedium);
        navigate(newURL);
    }
  
  };

export function getUTMParams(location) {
    const searchParams = new URLSearchParams(location.search);
    const utmSource = searchParams.get('utm_source');
    const utmMedium = searchParams.get('utm_medium');
    const redirect = searchParams.get('redirect');
    return { utmSource, utmMedium, redirect };
}




import { Container } from "react-bootstrap";
import HeaderComponent from "../header/header";
import "./questions.scss";
import ARROR_RIGHT from "../../assets/images/arrow-right.png";
import { useEffect, useState } from "react";
import QuestionsModel from "../../model/questions.model";
import QuestionItem from "../../model/questions.model";
import { useLocation, useNavigate } from "react-router-dom";
import { getUTMParams, handleNavigation } from "../../services/handler-utm.service";

const QuestionsComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [questionsModel, setQuestionsModel] = useState<QuestionsModel>();
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [isChangeQuestion, setIsChangeQuestion] = useState(false);
    const { utmSource, utmMedium } = getUTMParams(location);
    const [colorBorder, setColorBorder] = useState("#fff");

    const colorBorderList = [
        {
            "title" : "Judging",
            "color" : ""
        },
        {
            "title" : "Perceiving",
            "color" : ""
        },
        {
            "title" : "Extraversion",
            "color" : ""
        },
        {
            "title" : "Thinking",
            "color" : ""
        },
        {
            "title" : "Introversion",
            "color" : ""
        }
    ]
    useEffect(() => {
        try {
            if (location.state.input) {
                const questionsModel: QuestionsModel = location.state.input;
                console.log(location.state.input)
                if (questionsModel) {
                    questionsModel.answers = [];
                    setQuestionsModel(questionsModel);
                }

            }
        } catch {
            // handleNavigation(navigate, '/', utmSource, utmMedium);
        }

    }, [])

    const onClick = (answer) => {
        if (questionsModel) {
            if (currentQuestion + 1 < questionsModel.questions!.length) {
                const currentModel: QuestionsModel = questionsModel;
                if (currentModel.questions) {
                    currentModel.answers![currentQuestion] = answer;
                    setQuestionsModel(currentModel);

                    setIsChangeQuestion(true);
                    console.log(currentModel);
                }
            } else {
                const currentModel: QuestionsModel = questionsModel;
                if (currentModel.questions) {
                    currentModel.answers![currentQuestion] = answer;
                    setQuestionsModel(currentModel);
                    console.log(currentModel);
                    handleNavigation(navigate, '/loading', utmSource, utmMedium, null, currentModel);
                }
            }

        }
    }

    useEffect(() => {

        if (isChangeQuestion) {
            setTimeout(function () {
                setCurrentQuestion(currentQuestion + 1);
                setIsChangeQuestion(false);

            }, 500);
        }

    }, [isChangeQuestion])



    return (

        <>
            <div className="questions">
                <HeaderComponent></HeaderComponent>
                <Container className="questions-control">
                    {
                        (questionsModel && questionsModel.questions!.length > 0) &&


                        <div className={isChangeQuestion ? "questions-text-control fade-out" : "questions-text-control"}
                        >
                            <div className="questions-text-category-control">
                                <span className="questions-text-category"
                                style={
                                    questionsModel.questions![currentQuestion].category == "Judging" ? {borderColor : "#F875AE", color : "#F875AE"} :
                                    questionsModel.questions![currentQuestion].category == "Perceiving" ? {borderColor : "#54A8EB", color : "#54A8EB"} :
                                    questionsModel.questions![currentQuestion].category == "Extraversion" ? {borderColor : "#FDB134", color : "#FDB134"} :
                                    questionsModel.questions![currentQuestion].category == "Thinking" ? {borderColor : "#0D6EFD", color : "#0D6EFD"} :
                                    questionsModel.questions![currentQuestion].category == "Introversion" ? {borderColor : "#ffa5a5", color : "#ffa5a5"} :
                                    questionsModel.questions![currentQuestion].category == "work environment" ? {borderColor : "#F875AE", color : "#F875AE"} :
                                    
                                    {borderColor : "#6C7686", color : "#6C7686"}
                                }
                                >{questionsModel.questions![currentQuestion].category}</span>
                            </div>

                            <h2 className="questions-text-title"
                           
                            >{questionsModel.questions![currentQuestion].question}</h2>

                            <div className="questions-text-choice-control">
                                {
                                    questionsModel.questions![currentQuestion].choices!.map((item2: string, index2: number) => {
                                        return (
                                            <div className="questions-text-choice-item" key={index2} onClick={() => onClick(item2)}>
                                                <span className="questions-text-choicetext">{item2}</span>
                                                <div className="questions-arrow-img">
                                                    <img src={ARROR_RIGHT} className="questions-arrow-img"></img>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>

                    }





                </Container >

            </div >
        </>
    )
}

export default QuestionsComponent
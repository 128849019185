import apiPath from "../api-path"
import config from "../config"
import axios from "axios"


export default function ApiService() {

    return {
        async postGetResult(desc) {
            return fetch(config.chat_gpt.base_url + apiPath.path.result, {
                method: 'POST', headers: {
                    'content-type': 'application/json'
                }, body: JSON.stringify(desc)
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
        async postSubmitEmail(emailAddress, log_uuid) {
            return fetch(config.chat_gpt.base_url + apiPath.path.log + log_uuid+ apiPath.path.user, {
                method: 'POST', headers: {
                    'content-type': 'application/json'
                }, body: JSON.stringify(emailAddress)
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
        async postGetQuestion(desc) {
            return fetch(config.chat_gpt.base_url + apiPath.path.questions, {
                method: 'POST', headers: {
                    'content-type': 'application/json'
                }, body: JSON.stringify(desc)
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
        async sugestonPost(desc,path) {
            return fetch(config.chat_gpt.base_url + apiPath.resume + apiPath.generator + path, {
                method: 'POST', headers: {
                    'content-type': 'application/json'
                }, body: JSON.stringify(desc)
            }
            ).then(res => res.json()).catch(err => console.log(err))
        }, async uploadResume(fileUpload, filename) {
            const formData = new FormData();
            formData.append("resume", fileUpload, filename);
            formData.append("type", "resume-dating");
            return axios.post(config.upload_resume.base_url +"/resume/upload", formData, {
                headers: {
                    "x-api-key": config.upload_resume.x_api_key
                }
            })
                .then(res => res.data)
                .catch(errors => errors);
        },async getResumeId(resumeKey) {
            return fetch(config.chat_gpt.base_url + apiPath.resume + apiPath.generator + apiPath.path.upload, {
                method: 'POST', headers: {
                    'content-type': 'application/json'
                }, body: JSON.stringify(resumeKey)
            }
            ).then(res => res.json()).catch(err => console.log(err))
               
        },async getResumeUrl(resumeUUID) {
            return fetch(config.chat_gpt.base_url + apiPath.resume + apiPath.generator +resumeUUID, {
                method: 'GET', headers: {
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.json()).catch(err => console.log(err))
               
        },
    }
}
import { Button, Container } from "react-bootstrap";
import HeaderComponent from "../header/header";
import "./result.scss";
import HEART_RESULT_IMG from "../../assets/images/heart-result.png";
import H1_RESULT_IMG from "../../assets/images/h1.png";
import H2_RESULT_IMG from "../../assets/images/h2.png";
import H3_RESULT_IMG from "../../assets/images/h3.png";
import H4_RESULT_IMG from "../../assets/images/h4.png";
import H5_RESULT_IMG from "../../assets/images/h5.png";
import GQ_1_IMG from "../../assets/images/gq-1.jpeg";
import GQ_2_IMG from "../../assets/images/gq-2.jpeg";
import GQ_3_IMG from "../../assets/images/gq-3.jpeg";
import GQ_5_IMG from "../../assets/images/gq-5.jpeg";
import GQ_6_IMG from "../../assets/images/gq-6.jpeg";
import GQ_7_IMG from "../../assets/images/gq-7.jpeg";
import RESULT_BLUR_IMG from "../../assets/images/result-blur.png";
import FooterComponent from "../footer/footer";
import { useEffect, useState } from "react";
import { ResultModel } from "../../model/result.model";
import { useLocation, useNavigate } from "react-router-dom";
import EmailSubmitModal from "../../modal/email-submit.modal";
import GoogleMapEmbed from "../../services/google-map.service";
import UtilityService from "../../utility/utility.service";
const ResultComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [resultModel, setResultModel] = useState<ResultModel>();
    const [showEmailSubmit, setShowEmailSubmit] = useState(false);
    const gqIframe = "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FGQApparel%2Fposts%2Fpfbid0361GDaprK3pdTipG58wJFMocNpLV6WEmbXVZUAbNemjM7YLEyFAzom96GFDQ7bBbAl&show_text=true&width=500";

    useEffect(() => {
        if (location.state.input.mbti_description) {
            const resultModel: ResultModel = location.state.input;
            console.log(location.state.input);
            if (resultModel) {
                setResultModel(resultModel);
            }
        }
    }, [])

    const handleShowEmailSubmit = () => {

        if (showEmailSubmit) {
            setShowEmailSubmit(false);
        } else {
            setShowEmailSubmit(true);
        }
    }
    const getGGMapPlace = (place) => {
        const formattedPlace = encodeURIComponent(place);
        const mapLink = `https://www.google.com/maps/search/?api=1&query=${formattedPlace}`;
        return mapLink
    }

    const clickURL = (url, name) => {
        UtilityService().clickSendEvent("Click GQ " + name , "Click Ads GQ");
        window.open(url, "_blank");
    }

    return (

        <>
            <div className="result">
                <HeaderComponent ></HeaderComponent>
                {
                    resultModel && <>
                        <Container className="result-control">
                            <div className="result-heart-control">
                                <img className="result-h1-img" src={H1_RESULT_IMG}></img>
                                <img className="result-h2-img" src={H2_RESULT_IMG}></img>
                                <img className="result-h3-img" src={H3_RESULT_IMG}></img>
                                <img className="result-h4-img" src={H4_RESULT_IMG}></img>
                                <img className="result-heart-center" src={HEART_RESULT_IMG}></img>
                            </div>
                            <div className="result-text-control">
                                <h2 className="result-text-title">Congrats, you did it! Let’s review.</h2>
                                <span className="result-text-desc">{resultModel.dating_description}</span>
                                {
                                    resultModel.dating_description_th &&

                                    <span className="result-text-desc">{resultModel.dating_description_th}</span>
                                }
                            </div>
                            {
                                resultModel.dating_place &&
                                <div className="result-text-control">
                                    <h2 className="result-suggest-header">The perfect places for your date</h2>
                                    <span className="result-suggest-desc">{resultModel.dating_place.name}</span>
                                    <div className="result-gooogle-map">
                                        <GoogleMapEmbed place={resultModel.dating_place.name} /> {/* Example coordinates for the Eiffel Tower */}
                                    </div>

                                </div>
                            }
                            {
                                resultModel.dating_clothes_image &&
                                <div className="result-text-control">
                                    <h2 className="result-text-title">The outfit you should wear for dating</h2>
                                    <img src={resultModel.dating_clothes_image} className="result-img-dating"></img>
                                    <div className="result-gq-control">
                                        <img src={GQ_6_IMG} className="result-gq-img"></img>
                                        <img src={GQ_7_IMG} className="result-gq-img"></img>
                                        <img src={GQ_1_IMG} onClick={() => clickURL('https://gqsize.com/pages/gq-minimal-polo?utm_source=RESUME_DATING_SCOUTOUT', 'MINIMAL POLO')} className="result-gq-img"></img>
                                        <img src={GQ_2_IMG} onClick={() => clickURL('https://gqsize.com/pages/gq-chinopants-nm?utm_source=RESUME_DATING_SCOUTOUT', 'PERFECT CHINO')} className="result-gq-img"></img>
                                        <img src={GQ_3_IMG} onClick={() => clickURL('https://gqsize.com/pages/smellblock-socks?utm_source=RESUME_DATING_SCOUTOUT', 'SMELLBLOCK SOCKS')} className="result-gq-img"></img>
                                        <img src={GQ_5_IMG} onClick={() => clickURL('https://gqsize.com/pages/gqwhite-shirt?utm_source=RESUME_DATING_SCOUTOUT', 'GQ WHITE')}className="result-gq-img"></img>
                                    </div>
                                    <Button className="result-gq-button" onClick={() => clickURL('https://gqsize.com?utm_source=RESUME_DATING_SCOUTOUT', 'Button GQ')}>Goto GQ Shop</Button>
                                    {/* <iframe
                                        src={gqIframe}
                                        width="500" height="850" style={{ border: "none", overflow: "hidden" }}
                                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe> */}
                                </div>
                            }


                        </Container>
                        <Container className="result-divider-control">
                            <img src={HEART_RESULT_IMG} className="result-divider-img"></img>
                            <img src={HEART_RESULT_IMG} className="result-divider-img"></img>
                            <img src={HEART_RESULT_IMG} className="result-divider-img"></img>
                            <img src={HEART_RESULT_IMG} className="result-divider-img"></img>
                            <img src={HEART_RESULT_IMG} className="result-divider-img"></img>
                            <img src={HEART_RESULT_IMG} className="result-divider-img"></img>
                            <img src={HEART_RESULT_IMG} className="result-divider-img"></img>
                            <img src={HEART_RESULT_IMG} className="result-divider-img"></img>
                            <img src={HEART_RESULT_IMG} className="result-divider-img"></img>
                            <img src={HEART_RESULT_IMG} className="result-divider-img"></img>
                            <img src={HEART_RESULT_IMG} className="result-divider-img"></img>
                            <img src={HEART_RESULT_IMG} className="result-divider-img"></img>
                            <img src={HEART_RESULT_IMG} className="result-divider-img"></img>
                            <img src={HEART_RESULT_IMG} className="result-divider-img"></img>
                            <img src={HEART_RESULT_IMG} className="result-divider-img"></img>
                            <img src={HEART_RESULT_IMG} className="result-divider-img"></img>
                            <img src={HEART_RESULT_IMG} className="result-divider-img"></img>

                        </Container>
                        <Container className="result-match-control">

                            <span className="result-match-title">Discover the Perfect Match for You!</span>
                            <span className="result-match-desc">Dating isn't just about anyone; it's about finding someone who truly fits with us.
                                If you're interested in starting to date someone who resonates with you, register now!
                                We will match you with your most compatible partner and send the details to your email.</span>
                            <Button className="result-match-button" onClick={handleShowEmailSubmit}>Send your email</Button>
                            <img src={RESULT_BLUR_IMG} className="result-match-blur-img"></img>
                            <img className="result-match-h1-img" src={H3_RESULT_IMG}></img>
                            <img className="result-match-h2-img" src={H1_RESULT_IMG}></img>
                            <img className="result-match-h3-img" src={H4_RESULT_IMG}></img>
                            <img className="result-match-h4-img" src={H5_RESULT_IMG}></img>
                            <img className="result-match-h5-img" src={H2_RESULT_IMG}></img>
                        </Container>
                    </>
                }

                <FooterComponent></FooterComponent>
            </div>
            {
                resultModel &&
                <EmailSubmitModal open={showEmailSubmit} setOpen={handleShowEmailSubmit} log_uuid={resultModel?.log_uuid}></EmailSubmitModal>
            }

        </>
    )
}

export default ResultComponent;
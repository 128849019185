import { Button, Container } from "react-bootstrap";
import HeaderComponent from "../header/header";
import "./landing.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { getUTMParams, handleNavigation } from "../../services/handler-utm.service";
import RESUME_ROMANCE_IMG from "../../assets/images/resume-to-romance.png";
import RESUME_IMG from "../../assets/images/resume.png";
import HEART_IMG from "../../assets/images/heart.png";
import HEART_88_IMG from "../../assets/images/heart-88.png";
import FooterComponent from "../footer/footer";

const LandingComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { utmSource, utmMedium} = getUTMParams(location);

    const onclickStart = () => {
        handleNavigation(navigate, '/upload-resume', utmSource, utmMedium);
    }

    return (

        <>
            <div className="landing">
                <HeaderComponent></HeaderComponent>
                <Container className="landing-control">
                    <div className="landing-left-control order-md-0 order-1">
                        <img src={RESUME_ROMANCE_IMG} className="landing-left-img"></img>
                        <span className="landing-left-text">Revolutionize your dating journey with Resume Dating – where meaningful connections begin with compatibility. 
                        Uncover a new era of matchmaking that goes beyond appearances and dives deep into who you truly are.</span>

                        <Button className="landing-left-button" onClick={onclickStart}>Start Dating</Button>
                    </div>
                    <div className="landing-right-control order-md-1 order-0">
                        <img src={RESUME_IMG} className="landing-right-img"></img>
                    </div>
                </Container>
                <Container className="landing-section-2-control">
                    <img src={HEART_IMG} className="landing-section-2-img"></img>
                    <span className="landing-section-2-text">"Your life is a unique story, and your
                     dating approach should reflect that. With Resume Dating, you're not just another profile – 
                     you're a multidimensional individual with experiences, values, and aspirations. 
                     Present your authentic self through your professional journey, personal traits, and lifestyle choices, 
                     and let the power of compatibility guide you towards relationships that align on a profound level."</span>
                    <div className="landing-section-2-divider"></div>
                </Container>
                <Container className="landing-section-3-control">
                    <img src={HEART_88_IMG} className="landing-section-3-img"></img>
                    <div className="landing-section-3-text-control">
                        <span className="landing-section-3-text-title">Unlock the Power of Resumes</span>
                        <span className="landing-section-3-text-desc">Are you tired of superficial swiping and 
                        meaningless encounters? Elevate your dating experience with Resume Dating. By embracing 
                        a holistic understanding of who you are, you're taking a step towards genuine connections. 
                        Break the mold, step into a world of deeper compatibility, and discover partners who appreciate 
                        the real you. Join the movement towards more meaningful relationships today!</span>
                            <Button className="landing-section-3-button"  onClick={onclickStart}>Start Dating</Button>
                    </div>
                </Container>
                <FooterComponent></FooterComponent>
            </div>
        </>
    )
}

export default LandingComponent
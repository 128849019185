import "./header.scss";
import ARROW_LEFT_IMG from "../../assets/images/arrow-left-long.png";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LOGO_IMG from "../../assets/images/logo.png";

const HeaderComponent = () => {
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();
    const handleToggle = () => {
        setExpanded(!expanded);
    };


    const clickMenu = (source: string, path) => {
        // UtilityService().clickSendEvent(GAConfig.event.click, source)
        window.open(path, "_self");
    }

    return (

        <>
            <Navbar expand="lg" expanded={expanded} className='header'>
                <Container className="header-grid-control">
                    <div className="header-logo-control">
                        <img src={LOGO_IMG} className="header-logo-img" onClick={() => clickMenu('home', '/')}></img>
                    </div>

                    <div className="header-menu-center-control">
                        <Nav.Link className="header-menu-a" onClick={() => clickMenu('home', '/')}>Home</Nav.Link>
                        {/* <Nav.Link className="header-menu-a" onClick={() => clickMenu('home', '/')}>Resume Dating</Nav.Link> */}
                        <Nav.Link className="header-menu-a" onClick={() => clickMenu('home', 'https://resume-builder.jobhack.co?utm_source=RESUME_DATING')}>Resume Builder</Nav.Link>
                        <Nav.Link className="header-menu-a" onClick={() => clickMenu('home', 'https://resume-checker.jobhack.co?utm_source=RESUME_DATING')}>Resume Checker</Nav.Link>
                        <Nav.Link className="header-menu-a" onClick={() => clickMenu('home', 'https://interview.jobhack.co?utm_source=RESUME_DATING')}>Interview AI</Nav.Link>
                    </div>

                    <div className="header-menu-nav-mobile-control">
                        <Navbar.Toggle aria-controls="navbar" onClick={handleToggle} />
                    </div>

                </Container>



                {
                    expanded && <div className="header-navbar-side" >
                       

                        <Nav.Link className="header-navbar-a" onClick={() => clickMenu('home', '/')}>Home</Nav.Link>
                        {/* <Nav.Link className="header-navbar-a" onClick={() => clickMenu('home', '/')}>Resume Dating</Nav.Link> */}
                        <Nav.Link className="header-navbar-a" onClick={() => clickMenu('home', 'https://resume-builder.jobhack.co?utm_source=RESUME_DATING')}>Resume Builder</Nav.Link>
                        <Nav.Link className="header-navbar-a" onClick={() => clickMenu('home', 'https://resume-checker.jobhack.co?utm_source=RESUME_DATING')}>Resume Checker</Nav.Link>
                        <Nav.Link className="header-navbar-a" onClick={() => clickMenu('home', 'https://interview.jobhack.co?utm_source=RESUME_DATING')}>Interview AI</Nav.Link>
                        <Nav.Link className="header-navbar-a" onClick={() => clickMenu('home', "https://scoutout.co/terms-th/")}>Terms & Condition</Nav.Link>
                        <Nav.Link className="header-navbar-a" onClick={() => clickMenu('home', "https://scoutout.co/privacy-th/")}>Privacy Policy</Nav.Link>
                    </div>
                }
            </Navbar>

        </>
    )
}

export default HeaderComponent
import "../components/result/result.scss";

const GoogleMapEmbed =({place} :any) => {
    const map = "https://www.google.com/maps/embed/v1/place?key=AIzaSyDO1IPRpvWzeP44a_x23R4j0gGH448kxp0&q="+place
    return (
        <div>
            <iframe
                className="result-gg-map-iframe"
                style={{ border: 0 }}
                src={map}
            >
            </iframe>
        </div>
    );
};

export default GoogleMapEmbed;
import React, { useState } from 'react';
import './App.scss';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import CookieConsent from 'react-cookie-consent';
import { Container } from 'react-bootstrap';
import { ProvideContext } from './context/provide.context';
import LandingComponent from './components/landing/landing';
import QuestionsComponent from './components/questions/questions';
import ResultComponent from './components/result/result';
import UploadResume from './components/upload-resume/upload-resume';
import LoadingComponent from './components/loading/loading';

function App() {

  return (
    <div className="App">
      <BrowserRouter>

        <ProvideContext.Provider
          value={{
           
          }}
        >
          <Routes>

            <Route path="/" element={<LandingComponent />} />
            <Route path="/loading" element={<LoadingComponent />} />
            <Route path="/upload-resume" element={<UploadResume />} />
            <Route path="/questions" element={<QuestionsComponent />} />
            <Route path="/result" element={<ResultComponent />} />
            <Route path='*' element={<LandingComponent />} />
          </Routes>
        </ProvideContext.Provider>
      </BrowserRouter>
      <CookieConsent
        enableDeclineButton
        declineButtonText="ยอมรับเท่าที่จำเป็น"
        location="bottom"
        buttonText="ยอมรับทั้งหมด"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        declineButtonStyle={{ fontSize: "13px", borderRadius: "12px" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px", borderRadius: "12px" }}
        expires={150}
      >
        <Container>


          เว็บไซต์ของเราใช้คุกกี้ เพื่อมอบประสบการณ์ที่ดียิ่งขึ้นแก่คุณ และเพื่อเสนอเนื้อหา
          บริการ และโฆษณาที่คุณอาจสนใจ คลิกดูข้อมูลเพิ่มเติมได้ที่  <a href="https://scoutout.co/terms-th/">เงื่อนไขการใช้บริการ</a>
          และ <a href="https://scoutout.net/privacy-th/">นโยบายความเป็นส่วนตัว</a>

        </Container>

      </CookieConsent>

    </div>
  );
}

export default App;
